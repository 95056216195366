<template>
  <div class="main">
    <div class="imger">
      <img :src="imger"></img>
    </div>
    <div class="content" style=" font-size: 25px;
    font-weight: bold;">
      <span>请求过于频繁，请稍后再试！</span>
    </div>
    <div class="btn" style="padding-top: 20px;">
      <el-button type="primary" round @click="btn">重试</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      imger: require("@/assets/code7.png")
    }
  },
  methods: {
    btn() {
      // this.$router.push({ path: "/home" });
      this.$router.go(-1)
    }
  }

}
</script>

<style scoped>
.main {
  width: 100%;
  text-align: center;
  position: relative;
  top: 35%;

  .imger {
    width: 100%;
  }

  .content {
    padding-top: 49px;
    font-size: 25px;
    font-weight: bold;
  }

  .btn {
    padding-top: 20px;
  }
}
</style>